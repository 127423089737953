
import React, { useState } from "react";
import { Select } from "antd";

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  let props_ = {...props}
  delete props_['placeholderInput']
  delete props_['optionList']
  delete props_['focus']
  return (
    <div
      className="float-label"
      onBlur={/* istanbul ignore next */() => {
        setFocus(false)
      }}
      data-testid="input-select-id"
      onFocus={/* istanbul ignore next */() => setFocus(true)}
    >
      <Select {...props_} placeholder={focus || props.focus?props.placeholderInput:''}  >
      {(props.optionList || []).map( /* istanbul ignore next */ (r,i) => {
        return (
          <Select.Option  key={i} value={r.value} name={r.name}> {r.name}</Select.Option>
        );
      })}
      </Select>
      <label htmlFor={props.id} className={`${labelClass} ${props.disabled?'disabled':''}`}>
        {props.required?<span style={{color:'#D1333D'}}> * </span>:<span style={{color:'#D1333D'}}>  </span>} {isOccupied ? label : placeholder}
      </label>
    </div>
  );
};

export default FloatInput;
