
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  Alert,
} from "antd";
import { createPartner as create, getCountry, getCity} from "../services/PublicForm";
import logoNN from "../asset/nn1.png"
import thankImg from "../asset/channel-partner.png"
import Privacy from "../component/privacy-policy";
import Input from "../component/FloatInput/index"
import Select from "../component/FloatSelect/index"
import { Helmet } from 'react-helmet';

const App = () => {
  const initialized = useRef(false);
  const cCount = useRef(0);
  const [form] = Form.useForm();

  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [cityChannelPartner, setCityChannelPartner] = useState([]);
  const [countryChannelPartner, setcountryChannelPartner] = useState([]);


  useEffect(/* istanbul ignore next */ () => {
     /* istanbul ignore else */
    if(!initialized.current){
      getCountry()
        .then((res) => {
          let resData = res.data;
           /* istanbul ignore else */
          if (resData && resData.resultCode === "20000" && resData.resData && resData.resData.data) {
              setcountryChannelPartner(resData.resData.data);
                try {
                  if(resData.resData.data.length){
                      form.setFieldValue('country',1);
                  }
                } catch (e) {
                }
          }
        }).catch((error) => {});

      initialized.current = true;
    };
  }, []);

  const handleSearch =  /* istanbul ignore next */ (e) => {
    let searchLength = 3
    cCount.current = e.length
    if(cCount.current >= searchLength){
      let data = {
        city : e ,

      }
      if(form.getFieldValue('country')){
        data.addressL7Country =  form.getFieldValue('country') ? { "id": form.getFieldValue('country') } : null
      }

      getCity(data)
      .then((res) => {
        let resData = res.data;
        if (resData.resultCode === "20000") {
          if (resData && resData.resData && resData.resData.data && cCount.current >= searchLength) {
            setCityChannelPartner(resData.resData.data);
          }
        }
      }).catch((error) => {});
    }else{
      setCityChannelPartner([]);
    }
  }

  const countryChannelPartnerChange = /* istanbul ignore next */ (value) => {
    form.resetFields(["city"]);
  };

  const onFinish = async (v) => {
      let payload = {
        cFirstName : v.firstName,
        cMidName : v.middleName,
        cLastName : v.lastName,
        name : v.firstName + (v.middleName ? " " + v.middleName : "") + " " + v.lastName,
        companyName : v.companyName,
        phone : v.phone,
        email : v.email,
        country : v.country,
        city : v.city,
        location : v.location,
        note : v.note,
        website : v.website
      }

      setLoading(true);
      create(payload)
        .then((res) => {
          let resData = res.data;
          if (resData.resultCode === "20000") {
            setAlert({
              message: "Success",
              type: "success",
            });
            form.resetFields();
            setSuccess(true);
            setLoading(false);
          } else {
            setLoading(false);
            setAlert({
              message: resData.resultDescription,
              type: "error",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert({
            message: "Error",
            type: "error",
          });
        });

  };


  return (<>
    <Helmet>
      <title>[NodesNow] Channel Partnership</title>
    </Helmet>

      <div className="main-container">
      <div className={`public-form `}>
        <div className="ui-form">

          {!success ? (
              <>
                <div className="logo-container">
                  <img src={logoNN} className="img-logo" alt=""></img>
                </div>
                <div className="ui-h1" style={{marginBottom:20}}>
                  <span className="">Apply for a NodesNow Channel Partnership</span>
                </div>
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={ /* istanbul ignore next */ (e)=> { }}
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  initialValues={{}}
                  size={"default"}
                  style={{
                    maxWidth: 600,
                  }}
                >
                  <Form.Item

                    name="firstName"
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Input label="Contact First Name" placeholderInput="Type your contact first name" required/>
                  </Form.Item>

                  <Form.Item

                    name="middleName"
                    rules={[]}
                  >
                    <Input label="Contact Middle Name"   placeholderInput="Type your contact middle name"/>
                  </Form.Item>

                  <Form.Item

                    name="lastName"
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Input   label="Contact Last Name" required placeholderInput="Type your contact last name"/>
                  </Form.Item>

                  <Form.Item
                    name="companyName"
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Input label="Company" required placeholderInput="Type your company"/>
                  </Form.Item>

                  <Form.Item  name="phone" rules={[{ required: true, message: "This field is required" }]}>
                    <Input label="Phone Number" placeholderInput="+12015550123" required />
                  </Form.Item>

                  <Form.Item

                    name="email"
                    rules={[
                      { required: true, message: "This field is required" },
                      { type: "email", message: "Enter a valid email address" },
                    ]}
                  >
                    <Input   label="Email Address" required placeholderInput="Type your email address"/>
                  </Form.Item>

                  <Form.Item
                    name="location"
                    rules={[]}
                  >
                    <Input  placeholderInput="Type your address" label="Address"/>
                  </Form.Item>

                  {countryChannelPartner.length > 1 ? (
                    <>
                      <Form.Item

                        name="country"
                        rules={[]}
                      >
                        <Select
                          label="Country (optional)"
                          onChange={/* istanbul ignore next */(e) => {
                            countryChannelPartnerChange(e);
                          }}
                          showSearch
                          filterOption={ /* istanbul ignore next */(input, option) => {
                             return (option?.name ?? '').includes(input);
                          }}
                          optionList={countryChannelPartner.map( /* istanbul ignore next */ (r)=>{
                            return {value:r.id ,name:`${r.name}`}
                          })}
                        >
                        </Select>
                      </Form.Item>
                    </>
                  ) : <div style={{display: 'none'}}>Country No Data</div>}


                  <Form.Item

                    name="city"
                    rules={[]}
                  >
                    <Select
                      data-length={cityChannelPartner.length}
                      showSearch
                      label="City"
                      placeholderInput="Search at least 3 characters"
                      allowClear
                      onSearch={ /* istanbul ignore next */ handleSearch}
                      onClear={ /* istanbul ignore next */ ()=>setCityChannelPartner([])}
                      notFoundContent={null}
                      filterOption={ /* istanbul ignore next */(input, option) => {
                        return (option?.name ?? '').toLowerCase().includes((input || '').toLowerCase());
                      }}
                      optionList={cityChannelPartner.map( /* istanbul ignore next */(r)=>{
                        return {value:r.id ,name:`${r.name}, ${r.state || ''}, ${r.zip || ''}`}
                      })}
                      >
                    </Select>
                  </Form.Item>

                  <Form.Item name="website" rules={[]}>
                    <Input placeholderInput="Type your website" label="Website (optional)"  />
                  </Form.Item>

                  <Form.Item  name="note" rules={[]}>
                    <Input placeholderInput="Type your message" label="Message (optional)" />
                  </Form.Item>

                  <Privacy
                    type = "channelpartner"
                    onChecked = {(e)=>{
                      setBtnDisabled(!e)
                    }}
                  />

                  <Form.Item label="">
                    <Button
                      disabled={btnDisabled}
                      type="primary"
                      block
                      loading={loading}
                      data-testid="summit"
                      onClick={() => {
                        form.submit()
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>

                {/**  <div className="ui-h5">
                    <span className="">
                      Never share sensitive information (credit card numbers, social
                      security numbers, passwords) through this form.
                    </span>
                  </div>
                  **/}
                </Form>
              </>
          ) : (
              <>
                <div className="text-center">
                  <img src={thankImg} height={"100px"} alt=""></img>
                  <h2>We have received your form</h2>
                  <p>Thank you for completing the form! Your request is being processed and may take 1-2 weeks. We will keep you updated on its progress.</p>
                </div>
              </>
          )}

          {alert && alert.type === "error" ? (
            <div style={{ marginTop: 20 }}>
              <Alert message={alert.message} type={alert.type} showIcon data-testid="show-alert"/>
            </div>
          ) : null}
        </div>
      </div>
      </div>
  </>);
};

export default App;
