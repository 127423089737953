
import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Divider
} from 'antd'
import Input from '../../component/FloatInput/index'
import Select from '../../component/FloatSelect/index'
import {phoneCode} from '../../services/phoneCode'
import CityModal from '../../component/add-city'

const LeadStep2 = (props) => {
  const [form] = Form.useForm()

  const [open, setOpen] = useState(false)
  const [countryCloneId, setCountryCloneId] = useState()
  useEffect(/* istanbul ignore next */() => {
    form.setFieldsValue(props.data ? props.data : {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (v) => {
    props.onNext(v)
  }

  const addNewCity = () =>{
    setOpen(true)
    let c = props.country.find((r) => {
      return r.id === form.getFieldValue('address_country')
    })
    setCountryCloneId(c)
  }

  const onCitySuccess = async (dataId, data) =>{
    await props.handleCitySearch(data.zipCode, 'main', form.getFieldValue('address_country'))
    form.setFieldValue('address_city', dataId.data)
  }

  return <div className='nn-fm'>
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={/* istanbul ignore next */(e) => {

      }}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      layout='vertical'
      initialValues={{}}
      size={'default'}
    >

      <div className='nn-fm-subheader'>
        Management Company Information
      </div>

      <div className='nn-fm-groupheader'>
        Company Details
      </div>
      <Form.Item
        name='name_of_management_company'
        rules={[
          { required: true, message: 'This field is required' }
        ]}
      >
        <Input required label='Name of Management Company' placeholderInput='Type your name of management company' />
      </Form.Item>
      <Form.Item
        name='name_of_property'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input required label='Name of Property' placeholderInput='Type your name of property' />
      </Form.Item>

      <div className='nn-fm-groupheader'>
        Management Company Address
      </div>
      <Form.Item
        name='location'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input
          required
          label='Location of Management Company'
          placeholderInput='Type your location of management'
        />
      </Form.Item>

      {props.country && props.country.length > 1 ? (
        <>
          <Form.Item
            name='address_country'
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select
              required
              label='Address Country'
              showSearch
              onChange={ /* istanbul ignore next */ (e) => {
                props.countryChange(e, 'main')
                form.setFieldValue('address_city', null)
              }}
              filterOption={ /* istanbul ignore next */(input, option) => {
                return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
              }}
              optionList={props.country ? props.country.map( /* istanbul ignore next */(r) => {
                return { value: r.id, name: `${r.name}` }
              }) : []}
            >
            </Select>
          </Form.Item>
          <button data-testid='address_country' type='button' onClick={ /* istanbul ignore next */ () => {
            form.setFieldValue('address_country', 'test')
          }} style={{ display: 'none' }}> Address Country Test</button>

        </>
      ) : <div style={{ display: 'none' }}> Address Country No Data</div>}

      <div data-testid='address_city_length' style={{ display: 'none' }}>
        {props.city ? props.city.length : 0}
      </div>

      <Form.Item
        name='address_city'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select
          required
          data-length={props.city ? props.city.length : 0}
          showSearch
          label='Address City'
          placeholderInput='Search at least 3 characters'
          allowClear
          onSearch={/* istanbul ignore next */ (e) => (props.handleCitySearch(e, 'main', form.getFieldValue('address_country')))}
          onClear={/* istanbul ignore next */() => props.setCity([], 'main')}
          notFoundContent={props.sData && props.cCount.current >= 3 ? (
            <div style={{padding:"10px"}}>
              <p>No results found</p>
              <Button block onClick={()=>addNewCity()}>Add New City</Button>
            </div>
          ) : null}
          filterOption={/* istanbul ignore next */(input, option) => {
            return (option?.name ?? '').toLowerCase().includes((input || '').toLowerCase())
          }}
          optionList={props.city ? props.city.map(/* istanbul ignore next */(r) => {
            return { value: r.id, name: `${r.name}, ${r.state ? r.state+"," : ''} ${r.zip || ''}` }
          }) : []}
          loading={props.cityloading}
        >
        </Select>
      </Form.Item>

      <button data-testid='address_city' type='button' onClick={ /* istanbul ignore next */ () => {
        form.setFieldValue('address_city', 'test')
      }} style={{ display: 'none' }}> Address City Test</button>

      <div className='nn-fm-groupheader'>
        Key Contact
      </div>
      <Form.Item
        name='title'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input required label='Job Title' placeholderInput='Type your job title' />
      </Form.Item>

      <Form.Item
        name='name'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input required label='Full Name' placeholderInput='Type your name' />
      </Form.Item>

      <Form.Item
        name='email'
        rules={[
          { required: true, message: 'This field is required' },
          { type: 'email', message: 'Enter a valid email address' },
        ]}
      >
        <Input required placeholderInput='Type your email' label='Email' />
      </Form.Item>


      <div className='phone-box'>
        <Form.Item name={`prefix`} rules={[{ required: false, message: '' }]}>
          <Select
              label='Prefix'
              showSearch
              filterOption={(input, option) => {
                return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
              }}
              style={{height:"46px"}}
              optionList={phoneCode.map((r) => {
                return { value: r.dial, name: `${r.dial} ${r.code}` }
              })}
          >
          </Select>
        </Form.Item>
        <Form.Item
          name={`phone`}
          rules={[
            { required: true, message: 'This field is required' }

          ]}
        >
          <Input
            required
            placeholderInput={`2015550123`}
            label={`Phone`}
          />
        </Form.Item>
      </div>

      {/* <Form.Item name='phone' rules={[
        { required: true, message: 'This field is required' },
      ]}>
        <Input required label='Phone' placeholderInput='+12015550123' />
      </Form.Item> */}
      <div className='phone-box'>
        <Form.Item name={`prefax`} rules={[{ required: false, message: '' }]}>
          <Select
              label='Prefix'
              showSearch
              filterOption={(input, option) => {
                return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
              }}
              style={{height:"46px"}}
              optionList={phoneCode.map((r) => {
                return { value: r.dial, name: `${r.dial} ${r.code}` }
              })}
          >
          </Select>
        </Form.Item>
        <Form.Item name='fax' rules={[]}>
          <Input label='Fax (optional)' placeholderInput='2015550123' />
        </Form.Item>
      </div>

      <Form.Item name='note' rules={[]}>
        <Input label='Message (optional)' placeholderInput='Type your message' />
      </Form.Item>
      <Divider />
      <Form.Item label=''>
        <div className='nn-space-between-xl'>
          <Button
            loading={false}
            data-testid='back'
            onClick={() => {
              props.onBack()
            }}
            block
            style={{
              flex: '1'
            }}
          >
            BACK
          </Button>

          <Button
            type='primary'
            loading={false}
            data-testid='summit'
            onClick={() => {
              form.submit()
            }}
            block
            style={{
              flex: '1.5'
            }}
          >
            NEXT
          </Button>
        </div>
      </Form.Item>
    </Form>
    <CityModal setOpen={setOpen} open={open} countryId={countryCloneId} success={onCitySuccess}/>
  </div>
}

export default LeadStep2
