
import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Alert,
  Steps,
} from 'antd'
import { Helmet } from 'react-helmet'

import {
  postCreateLead,
  getCountry,
  getCity,
  getColumnLayout
} from '../services/PublicForm'
import logoNN from '../asset/logo.svg'
import logoNNSm from '../asset/logo-sm.svg'
import logoBusinessDeal1 from '../asset/business-deal-1.svg'
import logoBusinessDeal from '../asset/business-deal-2.svg'
import FormStep1 from './FormStep/FormStep1'
import FormStep2 from './FormStep/FormStep2'
import FormStepCompanyInformation1 from './FormStep/FormStepCompanyInformation1'

const items = [{}, {}, {}, {}, {}, {}]

const App = () => {
  const cCount = useRef(0)
  const initialized = useRef(false)
  const [steps, setSteps] = useState(0)
  const [data, setData] = useState({
    address_country: 1,
    contract_signature_country: 1,
    billing_contact_country: 1,
    delivery_contact_country: 1,
    communication_contact_country: 1,
    second_contact_country: 1,
    prefix:"+1",
    contract_signature_prefix : "+1",
    billing_contact_prefix : "+1",
    delivery_contact_prefix : "+1",
    communication_contact_prefix : "+1",
    second_contact_prefix : "+1",
    prefax : "+1",
  })
  const [cityAddress, setCityAddress] = useState([])
  const [countryAddress, setCountryAddress] = useState([])

  const [cityContractSignature, setCityContractSignature] = useState([])
  const [countryContractSignature, setCountryContractSignature] = useState([])

  const [cityBilling, setCityBilling] = useState([])
  const [countryBilling, setCountryBilling] = useState([])

  const [cityDelivery, setCityDelivery] = useState([])
  const [countryDelivery, setCountryDelivery] = useState([])

  const [cityNotice, setCityNotice] = useState([])
  const [countryNotice, setCountryNotice] = useState([])

  const [citySecond, setCitySecond] = useState([])
  const [countrySecond, setCountrySecond] = useState([])

  const [contractChecked, setContractChecked] = useState(false)
  const [billingChecked, setBillingChecked] = useState(false)
  const [deliveryChecked, setDeliveryChecked] = useState(false)
  const [noticeChecked, setNoticeChecked] = useState(false)
  const [secondChecked, setSecondChecked] = useState(true)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)


  const [btnDisabled, setBtnDisabled] = useState(false)
  const [alert, setAlert] = useState({})
  const [eventData, setEventData] = useState({})
  const [sData, setSData] = useState(true)

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      onload()
      initialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onload = async () => {
    try {
      await fnGetCountry()
      await fnGetColumnLayout()
    } catch (error) {
      console.error(error)
    }
  }

  const fnGetCountry = () => {
    return new Promise((resolve, reject) => {
      getCountry()
        .then(res => {
          const { resData } = res.data
          const countryData = resData.data
          setCountryAddress(countryData)
          setCountryContractSignature(countryData)
          setCountryBilling(countryData)
          setCountryDelivery(countryData)
          setCountryNotice(countryData)
          setCountrySecond(countryData)
          resolve()
        }).catch(error => {
          reject()
        })
    })
  }

  const fnGetColumnLayout = () => {
    return new Promise((resolve, reject) => {
      getColumnLayout()
        .then(res => {
          const { resData } = res.data
          if (resData?.image && resData?.html)
            setEventData({
              is_active: true,
              img: resData.image,
              html: resData.html
            })
          resolve()
        }).catch(error => {
          reject()
        })
    })
  }

  const fnPostCreateLead = (data) => {
    setLoading(true)
    postCreateLead(data)
      .then(res => {
        setAlert({
          message: 'Success',
          type: 'success',
        })
        setSuccess(true)
        setLoading(false)
        setData({
          address_country: 1,
          contract_signature_country: 1,
          billing_contact_country: 1,
          delivery_contact_country: 1,
          communication_contact_country: 1,
          second_contact_country: 1
        })
        setSteps(0)

        setCityAddress([])
        setCountryAddress([])

        setCityContractSignature([])
        setCountryContractSignature([])

        setCityBilling([])
        setCountryBilling([])

        setCityDelivery([])
        setCountryDelivery([])

        setCityNotice([])
        setCountryNotice([])

        setCitySecond([])
        setCountrySecond([])

        setContractChecked(false)
        setBillingChecked(false)
        setDeliveryChecked(false)
        setNoticeChecked(false)
        setSecondChecked(true)
      })
      .catch(error => {
        setLoading(false)
        setAlert({
          message: 'Error',
          type: 'error',
        })
      })
  }

  const fnGetCity = (data) => {
    return new Promise((resolve, reject) => {
      getCity(data)
        .then((res) => {
          const { resData } = res.data
          resolve(resData)
        }).catch((error) => {
          console.error(error)
          reject()
        })
    })
  }

  const onChangeCheckbox = (e, type) => {
    switch (type) {
      case 'contract_signature':
        setContractChecked(e)
        break
      case 'billing_contact':
        setBillingChecked(e)
        break
      case 'delivery_contact':
        setDeliveryChecked(e)
        break
      case 'communication_contact':
        setNoticeChecked(e)
        break
      case 'second_contact':
        setSecondChecked(e)
        break
      default:
    }
  }

  const onSetCity = /* istanbul ignore next */  (e, type) => {
    switch (type) {
      case 'main':
        setCityAddress(e)
        break
      case 'contract_signature':
        setCityContractSignature(e)
        break
      case 'billing_contact':
        setCityBilling(e)
        break
      case 'delivery_contact':
        setCityBilling(e)
        break
      case 'communication_contact':
        setCityNotice(e)
        break
      case 'second_contact':
        setCitySecond(e)
        break
      default:
    }
  }

  const onSubmit = (data) => {
    const mapData = [
      {
        name: 'contract_signature',
        checked: contractChecked
      },
      {
        name: 'billing_contact',
        checked: billingChecked
      },
      {
        name: 'delivery_contact',
        checked: deliveryChecked
      },
      {
        name: 'communication_contact',
        checked: noticeChecked
      },
    ]
    for (let i = 0; i < mapData.length; i++) {
      if (mapData[i]?.checked) {
        data[`${mapData[i].name}_title`] = data.title
        data[`${mapData[i].name}_name`] = data.name
        data[`${mapData[i].name}_email`] = data.email
        data[`${mapData[i].name}_phone`] = data.phone
        data[`${mapData[i].name}_address`] = data.location
        data[`${mapData[i].name}_country`] = data.address_country
        data[`${mapData[i].name}_city`] = data.address_city
        data[`${mapData[i].name}_prefix`] = data.prefix
        if(data[`${mapData[i].name}_prefix`])
          data[`${mapData[i].name}_phone`] = data[`${mapData[i].name}_prefix`] + data[`${mapData[i].name}_phone`]
      }else{
        if(data[`${mapData[i].name}_prefix`])
          data[`${mapData[i].name}_phone`] = data[`${mapData[i].name}_prefix`] + data[`${mapData[i].name}_phone`]
      }
    }
    
    if(data[`prefix`])
      data[`phone`] = data[`prefix`] + data[`phone`]
    
    if(data[`prefax`] && data[`fax`])
      data[`fax`] = data[`prefax`] + data[`fax`]

    if(!secondChecked){
      if(data[`second_contact_prefix`] && data[`second_contact_phone`]){
        data[`second_contact_phone`] = data[`second_contact_prefix`] + data[`second_contact_phone`]
      }
    }else{
      delete data[`second_contact_title`]
      delete data[`second_contact_name`]
      delete data[`second_contact_email`]
      delete data[`second_contact_phone`]
      delete data[`second_contact_address`]
      delete data[`second_contact_country`]
      delete data[`second_contact_city`]
      delete data[`second_contact_prefix`]
    }
    delete data[`billing_contact_prefix`]
    delete data[`communication_contact_prefix`]
    delete data[`contract_signature_prefix`]
    delete data[`delivery_contact_prefix`]
    delete data[`prefax`]
    delete data[`prefix`]
    delete data[`second_contact_prefix`]
    fnPostCreateLead(data)
  }

  const countryChange = /* istanbul ignore next */ (e, type) => {
    switch (type) {
      case 'main':
        setCityAddress([])
        break
      case 'contract_signature':
        setCityContractSignature([])
        break
      case 'billing_contact':
        setCityBilling([])
        break
      case 'delivery_contact':
        setCityDelivery([])
        break
      case 'communication_contact':
        setCityNotice([])
        break
      case 'second_contact':
        setCitySecond([])
        break
      default:
    }
  }

  const handleCitySearch = /* istanbul ignore next */ async (e, type, country_data) => {
    try {
      const searchLength = 3
      cCount.current = e.length
      if (cCount.current >= searchLength) {
        const data = { city: e }
        if (country_data)
          data.addressL7Country = { 'id': country_data }

        const getCityRes = await fnGetCity(data)

        if (getCityRes?.data && cCount.current >= searchLength) {
          switch (type) {
            case 'main':
              setCityAddress(getCityRes.data)
              break
            case 'contract_signature':
              setCityContractSignature(getCityRes.data)
              break
            case 'billing_contact':
              setCityBilling(getCityRes.data)
              break
            case 'delivery_contact':
              setCityDelivery(getCityRes.data)
              break
            case 'communication_contact':
              setCityNotice(getCityRes.data)
              break
            case 'second_contact':
              setCitySecond(getCityRes.data)
              break
            default:
          }
          setSData(false)
        } else {
          switch (type) {
            case 'main':
              setCityAddress([])
              break
            case 'contract_signature':
              setCityContractSignature([])
              break
            case 'billing_contact':
              setCityBilling([])
              break
            case 'delivery_contact':
              setCityDelivery([])
              break
            case 'communication_contact':
              setCityNotice([])
              break
            case 'second_contact':
              setCitySecond([])
              break
            default:
          }
          setSData(true)
        }
      } else {
        switch (type) {
          case 'main':
            setCityAddress([])
            break
          case 'contract_signature':
            setCityContractSignature([])
            break
          case 'billing_contact':
            setCityBilling([])
            break
          case 'delivery_contact':
            setCityDelivery([])
            break
          case 'communication_contact':
            setCityNotice([])
            break
          case 'second_contact':
            setCitySecond([])
            break
          default:
        }
        setSData(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (<>
    <Helmet>
      <title>[NodesNow] Request Agreement</title>
    </Helmet>
    <div className='nn-container'>
      <div className={eventData.is_active ? 'app-ui-main' : ''} >
        <div className={eventData.is_active ? 'app-ui-left' : ''}>
          <div className='nn-card'>
            {!success && <>
              <img src={logoNN} className='img-logo-nn pc-mode' alt=''></img>
              <img src={logoNNSm} className='img-logo-nn mobile-mode' alt=''></img>
              <h1 className='header-h1'>  Request Agreement </h1>
              {steps === 0 && <>
                <img src={logoBusinessDeal} className='img-logo-business-deal' alt=''></img>
                <div className='nn-fm-detail'>
                  Let's get started. Take the next step in turnkey hybrid small meetings at <br />Marriott properties by completing this contract application today.
                </div>
              </>}
              <div className='nn-steps-current'> Step {steps + 1}  </div>
              <div className='nn-steps-view'>
                <Steps size='small' current={steps} items={items}
                  direction='horizontal' responsive={false} />
              </div>
              {steps === 0 &&
                <FormStep1
                  data={data}
                  onNext={(d) => {
                    setData({ ...data, ...d })
                    setSteps(1)
                  }}
                />
              }
              {steps === 1 &&
                <FormStep2 data={data}
                  onNext={(d) => {
                    setData({ ...data, ...d })
                    setSteps(2)
                  }}
                  onBack={() => {
                    setSteps(0)
                  }}
                  country={countryAddress}
                  city={cityAddress}
                  countryChange={ /* istanbul ignore next */ (data, key) => {
                    countryChange(data, key)
                  }}
                  handleCitySearch={ /* istanbul ignore next */ (data, key, country_data) => {
                    handleCitySearch(data, key, country_data)
                  }}
                  setCity={/* istanbul ignore next */ (data, key) => {
                    onSetCity(data, key)
                  }}
                  cCount={cCount}
                  sData={sData}
                />
              }
              {steps === 2 &&
                <FormStepCompanyInformation1 data={data} onNext={(d) => {
                  setData({ ...data, ...d })
                  setSteps(3)
                }}
                  onBack={() => {
                    setSteps(1)
                  }}
                  nameKey={'Contract Signature'}
                  valueKey={'contract_signature'}
                  nameHeader={'Contract Signature Information'}
                  namePlaceholderKey={'signature'}
                  countryCopy={countryAddress}
                  cityCopy={cityAddress}
                  country={countryContractSignature}
                  city={cityContractSignature}
                  cCount={cCount}
                  countryChange={ /* istanbul ignore next */  (data, key) => {
                    countryChange(data, key)
                  }}
                  handleCitySearch={ /* istanbul ignore next */ (data, key, country_data) => {
                    handleCitySearch(data, key, country_data)
                  }}
                  sData={sData}
                  setCity={ /* istanbul ignore next */  (data, key) => {
                    onSetCity(data, key)
                  }}
                  onChangeCheckbox={(data, key) => {
                    onChangeCheckbox(data, key)
                  }}
                  contractChecked={contractChecked}
                />
              }
              {steps === 3 &&
                <FormStepCompanyInformation1 data={data} onNext={(d) => {
                  setData({ ...data, ...d })
                  setSteps(4)
                }}
                  onBack={() => {
                    setSteps(2)
                  }}
                  nameKey={'Billing'}
                  valueKey={'billing_contact'}
                  nameHeader={'Billing Information'}
                  namePlaceholderKey={'billing contact'}
                  countryCopy={countryAddress}
                  cityCopy={cityAddress}
                  country={countryBilling}
                  city={cityBilling}
                  countryChange={ /* istanbul ignore next */ (data, key) => {
                    countryChange(data, key)
                  }}
                  handleCitySearch={ /* istanbul ignore next */ (data, key, country_data) => {
                    handleCitySearch(data, key, country_data)
                  }}
                  setCity={ /* istanbul ignore next */ (data, key) => {
                    onSetCity(data, key)
                  }}
                  onChangeCheckbox={(data, key) => {
                    onChangeCheckbox(data, key)
                  }}
                  contractChecked={billingChecked}
                  cCount={cCount}
                  sData={sData}
                />
              }
              {steps === 4 &&
                <FormStepCompanyInformation1 data={data} onNext={(d) => {
                  setData({ ...data, ...d })
                  setSteps(5)
                }}
                  onBack={() => {
                    setSteps(3)
                  }}
                  nameKey={'Delivery'}
                  valueKey={'delivery_contact'}
                  nameHeader={'Delivery Information'}
                  namePlaceholderKey={'contact'}
                  countryCopy={countryAddress}
                  cityCopy={cityAddress}
                  country={countryDelivery}
                  city={cityDelivery}
                  countryChange={/* istanbul ignore next */ (data, key) => {
                    countryChange(data, key)
                  }}
                  handleCitySearch={ /* istanbul ignore next */ (data, key, country_data) => {
                    handleCitySearch(data, key, country_data)
                  }}
                  setCity={/* istanbul ignore next */(data, key) => {
                    onSetCity(data, key)
                  }}
                  onChangeCheckbox={(data, key) => {
                    onChangeCheckbox(data, key)
                  }}
                  contractChecked={deliveryChecked}
                  cCount={cCount}
                  sData={sData}
                />
              }
              {steps === 5 &&
                <FormStepCompanyInformation1
                  data={data}
                  onNext={(d) => {
                    setData({ ...data, ...d })
                    onSubmit({ ...data, ...d })
                  }}
                  onBack={() => {
                    setSteps(4)
                  }}
                  namePlaceholderKey={'contact'}
                  nameKey={'Contact'}
                  valueKey={'communication_contact'}
                  nameHeader={'Address for communication'}
                  countryCopy={countryAddress}
                  cityCopy={cityAddress}
                  loading={loading}
                  country={countryNotice}
                  city={cityNotice}
                  isLast
                  city2={citySecond}
                  countryChange={/* istanbul ignore next */(data, key) => {
                    countryChange(data, key)
                  }}
                  handleCitySearch={ /* istanbul ignore next */ (data, key, country_data) => {
                    handleCitySearch(data, key, country_data)
                  }}
                  setCity={/* istanbul ignore next */(data, key) => {
                    onSetCity(data, key)
                  }}
                  onChangeCheckbox={(data, key) => {
                    onChangeCheckbox(data, key)
                  }}
                  contractChecked={noticeChecked}
                  contractCheckedSecond={secondChecked}
                  setBtnDisabled={(e) => setBtnDisabled(e)}
                  btnDisabled={btnDisabled}
                  isShowSecond
                  cCount={cCount}
                  sData={sData}
                />
              }
            </>}
            {success && <>
              <div className='text-center'>
                <div className='logo-container'>
                  <img src={logoNN} className='img-logo-nn pc-mode' alt=''></img>
                  <img src={logoNNSm} className='img-logo-nn mobile-mode' alt=''></img>
                </div>
                <img src={logoBusinessDeal1} height={'200px'} alt=''></img>
                <h2 className='header-h2'>Thank you for your submission!</h2>
                <p style={{ maxWidth: 414, marginLeft: 'auto', marginRight: 'auto', marginBottom: 128 }}>
                  We'll start processing your form now and will keep you updated every step of the way. We are excited about our upcoming collaboration!
                </p>
                <div>
                  <Button
                    type='primary'
                    data-testid='goto'
                    onClick={ /* istanbul ignore next */ () => {
                      window.open('https://nodesnow.com/board', '_blank')
                    }}
                    style={{ marginTop: 12 }}
                    block
                  >
                    Discover more about NodesNow technology
                  </Button>
                </div>
              </div>
            </>}
            {alert && alert.type === 'error' ? (
              <div style={{ marginTop: 20 }}>
                <Alert message={alert.message} type={alert.type} showIcon data-testid='show-alert' />
              </div>
            ) : <div style={{ display: 'none' }}>no alert</div>}
          </div>
        </div>
        {eventData.is_active ?
          <div className='app-ui-right'>
            <div style={{ display: 'none' }}> event preview </div>
            <div className='public-event' style={{ background: `url(${eventData?.img}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
              <div className='container'>
                {<div dangerouslySetInnerHTML={{ __html: (eventData?.html) }} />}
              </div>
            </div>
          </div>
          : <div style={{ display: 'none' }}>no event preview</div>
        }
      </div>
    </div>
  </>)
}

export default App