
import React , { useEffect, useState, useRef } from "react";
import {
  Modal,
  Form,
  Button,
  Alert,
} from "antd";
import Input from '../component/FloatInput/index'
import {
  postCreateCity,
} from '../services/PublicForm'
const App = (props) => {
  const [form] = Form.useForm()
  const initialized = useRef(false);
  const [alert, setAlert] = useState({})
  
  // const [open, setOpen] = useState(false);
  useEffect( /* istanbul ignore next */ () => {
     /* istanbul ignore else */
    if(!initialized.current){
      initialized.current = true;
    }
    form.setFieldValue("country", props.countryId?.name)
  }, [props]);

  // const showModal = () => {
  //   setOpen(true);
  // };
  const handleCancel = () => {
    props.setOpen(false);
    form.resetFields()
  };

  const onFinish = async(e) => {
    let data = e
    data.country = props.countryId?.id
    await postCreateCity(data)
      .then(res => {
        if(props.countryType){
          props.success(res.data, data, props.countryType)
        }else{
          props.success(res.data, data)
        }
        setAlert({
          message: 'Success',
          type: 'success',
        })
      })
      .catch(error => {
        setAlert({
          message: 'Error',
          type: 'error',
        })
      })
    handleCancel()
  };
  return <>
      <Modal
        open={props.open}
        wrapClassName="wrap-privacy-policy"
        onOk={handleCancel}
        title=""
        width={900}
        okText="Close"
        onCancel={handleCancel}
        footer={(
          <>
            <div className='nn-space-between-xl'>
              <Button
                data-testid='back'
                onClick={() => {
                  handleCancel()
                }}
                block
                style={{
                  flex: '1'
                }}
              >Cancel</Button>
              <Button
                type='primary'
                onClick={() => {
                  form.submit()
                }}
                block
                style={{
                  flex: '1'
                }}
              >Submit</Button>
            </div>
          </>
        )}
        >
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          layout='vertical'
          initialValues={{}}
          size={'default'}
        >
          <h3>Add City</h3>
        <Form.Item
          name='country'
          rules={[
            { required: true, message: 'This field is required' }
          ]}
        >
          <Input disabled required label='Country Name' placeholderInput='Type your country name'/>
        </Form.Item>
        <Form.Item
          name='city'
          rules={[
            { required: true, message: 'This field is required' }
          ]}
        >
          <Input required label='City Name' placeholderInput='Type your city name' />
        </Form.Item>
        <Form.Item
          name='state'
          rules={[
            { required: false, message: '' }
          ]}
        >
          <Input label='City State' placeholderInput='Type your city state' />
        </Form.Item>
        <Form.Item
          name='zipCode'
          rules={[
            { required: true, message: 'This field is required' }
          ]}
        >
          <Input required label='Zip Code' placeholderInput='Type your zip code' />
        </Form.Item>
        </Form>
        {alert && alert.type === 'error' ? (
              <div style={{ marginTop: 20 }}>
                <Alert message={alert.message} type={alert.type} showIcon data-testid='show-alert' />
              </div>
            ) : <div style={{ display: 'none' }}>no alert</div>}
      </Modal>
  </>;
};

export default App;
