
import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Form,
  Divider,
  Alert,
  Skeleton
} from 'antd'
import { Helmet } from 'react-helmet'
import {
  postCreateLead,
  getCountry,
  getCity,
  getColumnLayout
} from '../services/PublicForm'
import logoNN from '../asset/logo.svg'
import logoNNSm from '../asset/logo-sm.svg'
import Privacy from '../component/privacy-policy'
import Input from '../component/FloatInput/index'
import Select from '../component/FloatSelect/index'
import logoBusinessDeal1 from '../asset/business-deal-1.svg'
import logoBusinessDeal from '../asset/business-deal-2.svg'
import {phoneCode} from '../services/phoneCode'
import CityModal from '../component/add-city'

const App = () => {
  const initialized = useRef(false)
  const cCount = useRef(0)
  const [form] = Form.useForm()
  const [alert, setAlert] = useState({})
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [cityAddress, setCityAddress] = useState([])
  const [countryAddress, setCountryAddress] = useState([])
  const [eventData, setEventData] = useState({})
  const [loadingFirst, setLoadingFirst] = useState(true)
  const [sData, setSData] = useState(true)
  const [open, setOpen] = useState(false)
  const [countryCloneId, setCountryCloneId] = useState()

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      getCountry()
        .then((res) => {
          let resData = res.data
          /* istanbul ignore else */
          if (resData && resData.resultCode === '20000' && resData.resData && resData.resData.data) {
            setCountryAddress(resData.resData.data)
            try {
              if(resData.resData.data.length){
                form.setFieldValue('address_country',1)
              }
            } catch (e) {
            }
          }
        }).catch((error) => { })

      getColumnLayout()
        .then((res) => {
          let resData = res.data
          setLoadingFirst(false)
          /* istanbul ignore else */
          if (resData.resultCode === '20000') {
            setEventData({
              is_active: true,
              img: resData.resData.image,
              html: resData.resData.html
            })
          }
        }).catch((error) => {
          setLoadingFirst(false)
        })
      
      form.setFieldValue('prefix',"+1")
      form.setFieldValue('prefax',"+1")
      initialized.current = true
    }
  }, [])

  const handleSearch = /* istanbul ignore next */ (e) => {
    let searchLength = 3
    cCount.current = e.length
    if (cCount.current >= searchLength) {
      let data = { city: e }
      if (form.getFieldValue('address_country')) {
        data.addressL7Country = form.getFieldValue('address_country') ? { 'id': form.getFieldValue('address_country') } : null
      }
      getCity(data)
        .then((res) => {
          let resData = res.data
          if (resData.resultCode === '20000') {
            if (resData && resData.resData && resData.resData.data && cCount.current >= searchLength) {
              setCityAddress(resData.resData.data)
              setSData(false)
            }else{
              setCityAddress([])
              setSData(true)
            }
          }
        }).catch((error) => { })
    } else {
      setCityAddress([])
      
      setSData(false)
    }
  }

  const countryAddressChange = /* istanbul ignore next */ (value) => {
    form.resetFields(['address_city'])
    setCityAddress([])
  }

  const onFinish = async (values) => {
    setLoading(true)
    let data = values
    if(data[`prefix`])
      data[`phone`] = data[`prefix`] + data[`phone`]
    
    if(data[`prefax`] && data[`fax`])
      data[`fax`] = data[`prefax`] + data[`fax`]

    delete data[`prefax`]
    delete data[`prefix`]
    postCreateLead(data)
      .then((res) => {
        let resData = res.data
        if (resData.resultCode === '20000') {
          setAlert({
            message: 'Success',
            type: 'success',
          })
          form.resetFields()
          setSuccess(true)
          setLoading(false)
        } else {
          setLoading(false)
          setAlert({
            message: resData.resultDescription,
            type: 'error',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        setAlert({
          message: 'Error',
          type: 'error',
        })
      })
  }

  const addNewCity = () =>{
    setOpen(true)
    let c = countryAddress.find((r) => {
      return r.id === form.getFieldValue('address_country')
    })
    setCountryCloneId(c)
  }

  const onCitySuccess = async (dataId, data) =>{
    await handleSearch(data.zipCode)
    form.setFieldValue('address_city', dataId.data)
  }

  return (<>
    <Helmet>
      <title>[NodesNow] Request Agreement</title>
    </Helmet>
    <h1 style={{ display: 'none' }}> Lead </h1>
    {loadingFirst ? <>
      <div className='main-container'>
        <div className={`public-form`}>
          <div className='ui-form' style={{ height: 400 }}>
            <h1 style={{ display: 'none' }}> loading first </h1>
            <Skeleton active />
          </div>
        </div>
      </div>
    </> : <>
      <div className='app-ui-main'>
        <div className='app-ui-left'>
          <div className={`public-form`}>
            <div className='ui-form'>
              {!success ? (
                <>
                  <div className='logo-container'>
                    <img src={logoNN} className='img-logo-nn pc-mode' alt=''></img>
                    <img src={logoNNSm} className='img-logo-nn mobile-mode' alt=''></img>
                  </div>
                  <h1 style={{ textAlign: 'center' }} className='header-h1'>  Request Agreement </h1>
                  <div className='logo-container'>
                    <img src={logoBusinessDeal} className='img-logo-business-deal' alt=''></img>
                  </div>
                  <div className='nn-fm-detail'>
                    Let's get started. Take the next step in turnkey hybrid small meetings at <br />Marriott properties by completing this contract application today.
                  </div>

                  <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={/* istanbul ignore next */ (e) => {

                    }} labelCol={{ span: 24, }} wrapperCol={{ span: 24, }} layout='vertical' size={'default'} style={{ maxWidth: 600, }}
                  >
                    <div className='ui-h3'>
                      <span className='nn-fm-subheader'>Management Company Information</span>
                    </div>

                    <div className='nn-fm-groupheader'>
                      Company Details
                    </div>
                    <Form.Item
                      name='name_of_management_company'
                      rules={[
                        { required: true, message: 'This field is required' }
                      ]}
                    >
                      <Input required label='Name of Management Company'
                        placeholderInput='Type your name of management company' />
                    </Form.Item>

                    <Form.Item

                      name='name_of_property'
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <Input required label='Name of Property'
                        placeholderInput='Type your name of property' />
                    </Form.Item>

                    <div className='nn-fm-groupheader'>
                      Management Company Address
                    </div>
                    <Form.Item
                      name='location'
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <Input
                        required
                        label='Location of Management Company'
                        placeholderInput='Type your location of management'
                      />
                    </Form.Item>
                    {countryAddress.length > 1 ?
                      <>
                        <Form.Item
                          name='address_country'
                          rules={[{ required: true, message: 'This field is required' }]}
                        >
                          <Select
                            required
                            label='Address Country'
                            onChange={ /* istanbul ignore next */ (e) => {
                              countryAddressChange(e)
                            }}
                            showSearch
                            filterOption={ /* istanbul ignore next */(input, option) => {
                              return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                            }}
                            optionList={countryAddress.map( /* istanbul ignore next */(r) => {
                              return { value: r.id, name: `${r.name}` }
                            })}
                          >
                          </Select>
                        </Form.Item>
                      </>
                      : <div style={{ display: 'none' }}> Address Country No Data </div>}

                    <div data-testid='address_city_length' style={{ display: 'none' }}>
                      {cityAddress.length}
                    </div>

                    <Form.Item
                      name='address_city'
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <Select
                        required
                        label='Address City'
                        data-length={cityAddress.length}
                        showSearch
                        placeholderInput='Search at least 3 characters'
                        allowClear
                        onSearch={/* istanbul ignore next */ handleSearch}
                        onClear={ /* istanbul ignore next */ () => setCityAddress([])}
                        notFoundContent={sData && cCount.current >= 3 ? (
                          <div style={{padding:"10px"}}>
                            <p>No results found</p>
                            <Button block onClick={()=>addNewCity()}>Add New City</Button>
                          </div>
                        ) : null}
                        filterOption={/* istanbul ignore next */(input, option) => {
                          return (option?.name ?? '').toLowerCase().includes((input || '').toLowerCase())
                        }}
                        optionList={ /* istanbul ignore next */ cityAddress.map( /* istanbul ignore next */(r) => {
                          return { value: r.id, name: `${r.name}, ${r.state ? r.state+"," : ''} ${r.zip || ''}` }
                        })}
                      >
                      </Select>
                    </Form.Item>

                    <button data-testid='address_city' type='button' onClick={ /* istanbul ignore next */ () => {
                      form.setFieldValue('address_city', 'test')
                    }} style={{ display: 'none' }}> Address City Test</button>

                    <div className='nn-fm-groupheader'>
                      Key Contact
                    </div>
                    <Form.Item
                      name='title'
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <Input required label='Job Title'
                        placeholderInput='Type your job title' />
                    </Form.Item>

                    <Form.Item

                      name='name'
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <Input required label='Full Name' placeholderInput='Type your name' />
                    </Form.Item>

                    <Form.Item

                      name='email'
                      rules={[
                        { required: true, message: 'This field is required' },
                        { type: 'email', message: 'Enter a valid email address' },
                      ]}
                    >
                      <Input required label='Email' placeholderInput='Type your email' />
                    </Form.Item>

                    <div className='phone-box'>
                      <Form.Item name={`prefix`} rules={[{ required: false, message: '' }]}>
                        <Select
                            label='Prefix'
                            showSearch
                            filterOption={(input, option) => {
                              return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                            }}
                            style={{height:"46px"}}
                            optionList={phoneCode.map((r) => {
                              return { value: r.dial, name: `${r.dial} ${r.code}` }
                            })}
                        >
                        </Select>
                      </Form.Item>
                      <Form.Item name='phone' rules={[{ required: true, message: 'This field is required' }]}>
                        <Input required label='Phone' placeholderInput='2015550123' />
                      </Form.Item>
                    </div>

                    <div className='phone-box'>
                      <Form.Item name={`prefax`} rules={[{ required: false, message: '' }]}>
                        <Select
                            label='Prefix'
                            showSearch
                            filterOption={(input, option) => {
                              return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
                            }}
                            style={{height:"46px"}}
                            optionList={phoneCode.map((r) => {
                              return { value: r.dial, name: `${r.dial} ${r.code}` }
                            })}
                        >
                        </Select>
                      </Form.Item>
                      <Form.Item name='fax' rules={[]}>
                      <Input label='Fax (optional)' placeholderInput='2015550123' />
                    </Form.Item>
                    </div>

                    <Form.Item name='note' rules={[]}>
                      <Input label='Message (optional)' placeholderInput='Type your message' />
                    </Form.Item>

                    <Divider />

                    <Privacy
                      type='lead'
                      onChecked={ /* istanbul ignore next */ (e) => {
                        setBtnDisabled(!e)
                      }}
                    />



                    <Form.Item label=''>
                      <Button
                        disabled={btnDisabled || loading}
                        type='primary'
                        block
                        loading={loading}
                        data-testid='summit'
                        onClick={() => {
                          form.submit()
                        }}
                      >
                        SUBMIT
                      </Button>
                    </Form.Item>
                  </Form>
                  
                  <CityModal setOpen={setOpen} open={open} countryId={countryCloneId} success={onCitySuccess}/>
                </>
              ) : (
                <>
                  <div className='text-center'>
                    <div className='logo-container'>
                      <img src={logoNN} className='img-logo-nn pc-mode' alt=''></img>
                      <img src={logoNNSm} className='img-logo-nn mobile-mode' alt=''></img>
                    </div>
                    <img src={logoBusinessDeal1} height={'200px'} alt=''></img>
                    <h2 className='header-h2'>Thank you for your submission!</h2>
                    <p style={{ maxWidth: 414, marginLeft: 'auto', marginRight: 'auto', marginBottom: 128 }}>
                      We'll start processing your form now and will keep you updated every step of the way. We are excited about our upcoming collaboration!
                    </p>
                    <Button
                      type='primary'
                      data-testid='goto'
                      onClick={ /* istanbul ignore next */ () => {
                        window.open('https://nodesnow.com/board', '_blank')
                      }}
                      style={{ marginTop: 12 }}
                      block
                    >
                      Discover more about NodesNow technology
                    </Button>
                  </div>
                </>
              )}

              {alert && alert.type === 'error' ? (
                <div style={{ marginTop: 20 }}>
                  <Alert message={alert.message} type={alert.type} showIcon data-testid='show-alert' />
                </div>
              ) : <div style={{ display: 'none' }}> no alert </div>}


            </div>
          </div>
        </div>
        {eventData.is_active ? <div className='app-ui-right'>
          <div style={{ display: 'none' }}> event preview </div>
          <div className='public-event' style={{ background: `url(${eventData?.img}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
            <div className='container'>
              {<div dangerouslySetInnerHTML={{ __html: (eventData?.html) }} />}
            </div>
          </div>
        </div> : <>
          <div style={{ display: 'none' }}> no event preview </div>
        </>}
      </div>
    </>}
  </>)
}

export default App