
import React , { useEffect, useState, useRef } from "react";
import {
  Checkbox,
  Modal,
} from "antd";
import { getPrivacy } from "../services/PublicForm";
const App = (props) => {
  const initialized = useRef(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [checked, setChecked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  useEffect( /* istanbul ignore next */ () => {
     /* istanbul ignore else */
    if(!initialized.current){
      getPrivacy(props.type)
        .then((res) => {
          let resData = res.data;
           /* istanbul ignore else */
          if (resData.resultCode === "20000") {
            setMessage(resData.resData[0]?.html || "")
            setHeader(resData.resData[0]?.header || "")

            if(resData.resData.length === 0){
                setIsActive(false)
                 /* istanbul ignore else */
                if(props.onChecked){
                  props.onChecked(true)
                }

            }else{
                /* istanbul ignore else */
                if(props.onChecked){
                  props.onChecked(false)
                }
                setIsActive(true)
            }
          } else {
            setIsActive(false)
             /* istanbul ignore else */
            if(props.onChecked){
              props.onChecked(true)
            }
          }
        }).catch((error) => {
          setIsActive(false)
           /* istanbul ignore else */
          if(props.onChecked){
            props.onChecked(true)
          }
        });
      initialized.current = true;
    }

  }, []);

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onChange =  (e) => {
    setChecked(e.target.checked);
    props.onChecked(e.target.checked)
  };
  return <>
    <div style={{display:'none'}}> Privacy Policy </div>
    {isActive? <>
      <div className="checkbox-container">
        <Checkbox onChange={onChange} checked={checked} data-testid="privacy-checkbox"></Checkbox>
        <div className="text-checkbox">
          By selecting "Submit", you are confirming that you have read and agree to NodesNow's <style className="link" data-testid="privacy-open" onClick={showModal}>Privacy Policy</style>
        </div>
      </div>
      <Modal
        open={open}
        wrapClassName="wrap-privacy-policy"
        onOk={handleCancel}
        title={header}
        width={900}
        okText="Close"
        onCancel={handleCancel}
        footer={(_, { OkBtn}) => (
          <>
            <OkBtn/>
          </>
        )}
        >
        <div style={{display:'none'}}> Privacy Policy Modal </div>
        { <div className="privacy-container" dangerouslySetInnerHTML={{ __html: (message) }} /> }
      </Modal>
    </> : <></>}
  </>;
};

export default App;
