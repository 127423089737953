
import React, { useState } from "react";
import { InputNumber } from "antd";
const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder } = props;

  if (!placeholder) placeholder = label;
  const isOccupied = focus || (value && value.length !== 0)  ||  value === 0;
  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";
  // const requiredMark = required ? <span className="text-danger">*</span> : null;

  let props_ = {...props}
  delete props_['placeholderInput']
  delete props_['focus']
  return (
    <div
      className="float-label"
      onBlur={/* istanbul ignore next */() => {
        setFocus(false)
      }}
      data-testid="input-number-id"
      onFocus={ /* istanbul ignore next */ () => setFocus(true)}
    >
      <InputNumber onChange={props.onChange} {...props_} placeholder={focus || props.focus?props.placeholderInput:''} style={{width:'100%'}}/>
      <label htmlFor={props.id} className={`${labelClass} ${props.disabled?'disabled':''}`}>
        {props.required?<span style={{color:'#D1333D'}}> * </span>:<span style={{color:'#D1333D'}}>  </span>} {isOccupied ? label : placeholder}
      </label>
    </div>
  );
};

export default FloatInput;
