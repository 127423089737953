
import { post, get } from './RequestApi'

function removeEmpty(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName]
    }
  }
  return obj
}

export function createPartner(data) {
  return post({ url: `/channel-partner/create`, data: removeEmpty(data) })
}

export function postCreateLead(data) {
  return post({ url: `/lead/create`, data: removeEmpty(data) })
}

export function getCity(data) {
  return post({ url: `/lead/city`, data })
}

export function getCountry(data) {
  return post({ url: `/lead/country`, data })
}

export function getPrivacy(data) {
  return get({ url: `/privacy-policy` + (data ? '/' + data : '') })
}

export function getColumnLayout() {
  return get({ url: `/lead/event` })
}

export function postCreateCity(data) {
  return post({ url: `/city`, data })
}
