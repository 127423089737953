
import React, { useEffect } from "react";
import {
  Button,
  Form,
  Divider
} from "antd";
import FloatInputNumber from "../../component/FloatInputNumber/index"
const LeadStep1 = (props) => {
  const [ form ] = Form.useForm();
  useEffect(/* istanbul ignore next */ () => {
    form.setFieldsValue( props.data ? props.data : {});
  }, []);
  const onFinish = (v) =>{
      props.onNext(v)
  }
  return <div className="nn-fm">
  <Form
    form={form}
    onFinish={onFinish}
    onFinishFailed={/* istanbul ignore next */(e)=>{

    }}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
    layout="vertical"
    initialValues={{}}
    size={"default"}
  >

  <div className="nn-fm-subheader">
    Number of NodesNow boards
  </div>


  <Form.Item
    name="range"
    rules={[
      { required: true, message: "This field is required" }
    ]}
  >
    <FloatInputNumber min={0} required label="Number of NodesNow boards"
    placeholderInput="Enter amount of boards (Number only)"
     />
  </Form.Item>
  <Divider/>
  <Form.Item label="">
    <Button
      type="primary"
      block
      loading={false}
      data-testid="summit"
      onClick={() => {
        form.submit()
      }}
    >
      NEXT
    </Button>
  </Form.Item>
  </Form>
  </div>
}

export default LeadStep1;
