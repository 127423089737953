const phoneCode = [
  {
    dial: "+1",
    code: "USA",
    country: "US",
  },
  {
    dial: "+93",
    code: "AFG",
    country: "Afghanistan",
  },
  {
    dial: "+355",
    code: "ALB",
    country: "Albania",
  },
  {
    dial: "+213",
    code: "DZA",
    country: "Algeria",
  },
  {
    dial: "+1-684",
    code: "ASM",
    country: "American Samoa",
  },
  {
    dial: "+376",
    code: "AND",
    country: "Andorra",
  },
  {
    dial: "+244",
    code: "AGO",
    country: "Angola",
  },
  {
    dial: "+1-264",
    code: "AIA",
    country: "Anguilla",
  },
  {
    dial: "+672",
    code: "ATA",
    country: "Antarctica",
  },
  {
    dial: "+1-268",
    code: "ATG",
    country: "Antigua & Barbuda",
  },
  {
    dial: "+54",
    code: "ARG",
    country: "Argentina",
  },
  {
    dial: "+374",
    code: "ARM",
    country: "Armenia",
  },
  {
    dial: "+297",
    code: "ABW",
    country: "Aruba",
  },
  {
    dial: "+61",
    code: "AUS",
    country: "Australia",
  },
  {
    dial: "+43",
    code: "AUT",
    country: "Austria",
  },
  {
    dial: "+994",
    code: "AZE",
    country: "Azerbaijan",
  },
  {
    dial: "+1-242",
    code: "BHS",
    country: "Bahamas",
  },
  {
    dial: "+973",
    code: "BHR",
    country: "Bahrain",
  },
  {
    dial: "+880",
    code: "BGD",
    country: "Bangladesh",
  },
  {
    dial: "+1-246",
    code: "BRB",
    country: "Barbados",
  },
  {
    dial: "+375",
    code: "BLR",
    country: "Belarus",
  },
  {
    dial: "+32",
    code: "BEL",
    country: "Belgium",
  },
  {
    dial: "+501",
    code: "BLZ",
    country: "Belize",
  },
  {
    dial: "+229",
    code: "BEN",
    country: "Benin",
  },
  {
    dial: "+1-441",
    code: "BMU",
    country: "Bermuda",
  },
  {
    dial: "+975",
    code: "BTN",
    country: "Bhutan",
  },
  {
    dial: "+591",
    code: "BOL",
    country: "Bolivia",
  },
  {
    dial: "+599",
    code: "BES",
    country: "Caribbean Netherlands",
  },
  {
    dial: "+387",
    code: "BIH",
    country: "Bosnia",
  },
  {
    dial: "+267",
    code: "BWA",
    country: "Botswana",
  },
  {
    dial: "+55",
    code: "BRA",
    country: "Brazil",
  },
  {
    dial: "+246",
    code: "IOT",
    country: "British Indian Ocean Territory",
  },
  {
    dial: "+1-284",
    code: "VGB",
    country: "British Virgin Islands",
  },
  {
    dial: "+673",
    code: "BRN",
    country: "Brunei",
  },
  {
    dial: "+359",
    code: "BGR",
    country: "Bulgaria",
  },
  {
    dial: "+226",
    code: "BFA",
    country: "Burkina Faso",
  },
  {
    dial: "+257",
    code: "BDI",
    country: "Burundi",
  },
  {
    dial: "+238",
    code: "CPV",
    country: "Cape Verde",
  },
  {
    dial: "+855",
    code: "KHM",
    country: "Cambodia",
  },
  {
    dial: "+237",
    code: "CMR",
    country: "Cameroon",
  },
  {
    dial: "+1-345",
    code: "CYM",
    country: "Cayman Islands",
  },
  {
    dial: "+236",
    code: "CAF",
    country: "Central African Republic",
  },
  {
    dial: "+235",
    code: "TCD",
    country: "Chad",
  },
  {
    dial: "+56",
    code: "CHL",
    country: "Chile",
  },
  {
    dial: "+86",
    code: "CHN",
    country: "China",
  },
  {
    dial: "+852",
    code: "HKG",
    country: "Hong Kong",
  },
  {
    dial: "+853",
    code: "MAC",
    country: "Macau",
  },
  {
    dial: "+57",
    code: "COL",
    country: "Colombia",
  },
  {
    dial: "+269",
    code: "COM",
    country: "Comoros",
  },
  {
    dial: "+242",
    code: "COG",
    country: "Congo - Brazzaville",
  },
  {
    dial: "+682",
    code: "COK",
    country: "Cook Islands",
  },
  {
    dial: "+506",
    code: "CRI",
    country: "Costa Rica",
  },
  {
    dial: "+385",
    code: "HRV",
    country: "Croatia",
  },
  {
    dial: "+53",
    code: "CUB",
    country: "Cuba",
  },
  {
    dial: "+357",
    code: "CYP",
    country: "Cyprus",
  },
  {
    dial: "+420",
    code: "CZE",
    country: "Czechia",
  },
  {
    dial: "+225",
    code: "CIV",
    country: "Côte d’Ivoire",
  },
  {
    dial: "+850",
    code: "PRK",
    country: "North Korea",
  },
  {
    dial: "+243",
    code: "COD",
    country: "Congo - Kinshasa",
  },
  {
    dial: "+45",
    code: "DNK",
    country: "Denmark",
  },
  {
    dial: "+253",
    code: "DJI",
    country: "Djibouti",
  },
  {
    dial: "+1-767",
    code: "DMA",
    country: "Dominica",
  },
  {
    dial: "+1-809",
    code: "DOM",
    country: "Dominican Republic",
  },
  {
    dial: "+1-829",
    code: "DOM",
    country: "Dominican Republic",
  },
  {
    dial: "+1-849",
    code: "DOM",
    country: "Dominican Republic",
  },
  {
    dial: "+593",
    code: "ECU",
    country: "Ecuador",
  },
  {
    dial: "+20",
    code: "EGY",
    country: "Egypt",
  },
  {
    dial: "+503",
    code: "SLV",
    country: "El Salvador",
  },
  {
    dial: "+240",
    code: "GNQ",
    country: "Equatorial Guinea",
  },
  {
    dial: "+291",
    code: "ERI",
    country: "Eritrea",
  },
  {
    dial: "+372",
    code: "EST",
    country: "Estonia",
  },
  {
    dial: "+268",
    code: "SWZ",
    country: "Eswatini",
  },
  {
    dial: "+251",
    code: "ETH",
    country: "Ethiopia",
  },
  {
    dial: "+298",
    code: "FRO",
    country: "Faroe Islands",
  },
  {
    dial: "+679",
    code: "FJI",
    country: "Fiji",
  },
  {
    dial: "+358",
    code: "FIN",
    country: "Finland",
  },
  {
    dial: "+33",
    code: "FRA",
    country: "France",
  },
  {
    dial: "+594",
    code: "GUF",
    country: "French Guiana",
  },
  {
    dial: "+689",
    code: "PYF",
    country: "French Polynesia",
  },
  {
    dial: "+241",
    code: "GAB",
    country: "Gabon",
  },
  {
    dial: "+220",
    code: "GMB",
    country: "Gambia",
  },
  {
    dial: "+995",
    code: "GEO",
    country: "Georgia",
  },
  {
    dial: "+49",
    code: "DEU",
    country: "Germany",
  },
  {
    dial: "+233",
    code: "GHA",
    country: "Ghana",
  },
  {
    dial: "+350",
    code: "GIB",
    country: "Gibraltar",
  },
  {
    dial: "+30",
    code: "GRC",
    country: "Greece",
  },
  {
    dial: "+299",
    code: "GRL",
    country: "Greenland",
  },
  {
    dial: "+1-473",
    code: "GRD",
    country: "Grenada",
  },
  {
    dial: "+1-671",
    code: "GUM",
    country: "Guam",
  },
  {
    dial: "+502",
    code: "GTM",
    country: "Guatemala",
  },
  {
    dial: "+44-1481",
    code: "GGY",
    country: "Guernsey",
  },
  {
    dial: "+224",
    code: "GIN",
    country: "Guinea",
  },
  {
    dial: "+245",
    code: "GNB",
    country: "Guinea-Bissau",
  },
  {
    dial: "+592",
    code: "GUY",
    country: "Guyana",
  },
  {
    dial: "+509",
    code: "HTI",
    country: "Haiti",
  },
  {
    dial: "+39-06",
    code: "VAT",
    country: "Vatican City",
  },
  {
    dial: "+504",
    code: "HND",
    country: "Honduras",
  },
  {
    dial: "+36",
    code: "HUN",
    country: "Hungary",
  },
  {
    dial: "+354",
    code: "ISL",
    country: "Iceland",
  },
  {
    dial: "+91",
    code: "IND",
    country: "India",
  },
  {
    dial: "+62",
    code: "IDN",
    country: "Indonesia",
  },
  {
    dial: "+98",
    code: "IRN",
    country: "Iran",
  },
  {
    dial: "+964",
    code: "IRQ",
    country: "Iraq",
  },
  {
    dial: "+353",
    code: "IRL",
    country: "Ireland",
  },
  {
    dial: "+44-1624",
    code: "IMN",
    country: "Isle of Man",
  },
  {
    dial: "+972",
    code: "ISR",
    country: "Israel",
  },
  {
    dial: "+39",
    code: "ITA",
    country: "Italy",
  },
  {
    dial: "+1-876",
    code: "JAM",
    country: "Jamaica",
  },
  {
    dial: "+81",
    code: "JPN",
    country: "Japan",
  },
  {
    dial: "+44-1534",
    code: "JEY",
    country: "Jersey",
  },
  {
    dial: "+962",
    code: "JOR",
    country: "Jordan",
  },
  {
    dial: "+254",
    code: "KEN",
    country: "Kenya",
  },
  {
    dial: "+686",
    code: "KIR",
    country: "Kiribati",
  },
  {
    dial: "+965",
    code: "KWT",
    country: "Kuwait",
  },
  {
    dial: "+996",
    code: "KGZ",
    country: "Kyrgyzstan",
  },
  {
    dial: "+856",
    code: "LAO",
    country: "Laos",
  },
  {
    dial: "+371",
    code: "LVA",
    country: "Latvia",
  },
  {
    dial: "+961",
    code: "LBN",
    country: "Lebanon",
  },
  {
    dial: "+266",
    code: "LSO",
    country: "Lesotho",
  },
  {
    dial: "+231",
    code: "LBR",
    country: "Liberia",
  },
  {
    dial: "+218",
    code: "LBY",
    country: "Libya",
  },
  {
    dial: "+423",
    code: "LIE",
    country: "Liechtenstein",
  },
  {
    dial: "+370",
    code: "LTU",
    country: "Lithuania",
  },
  {
    dial: "+352",
    code: "LUX",
    country: "Luxembourg",
  },
  {
    dial: "+261",
    code: "MDG",
    country: "Madagascar",
  },
  {
    dial: "+265",
    code: "MWI",
    country: "Malawi",
  },
  {
    dial: "+60",
    code: "MYS",
    country: "Malaysia",
  },
  {
    dial: "+960",
    code: "MDV",
    country: "Maldives",
  },
  {
    dial: "+223",
    code: "MLI",
    country: "Mali",
  },
  {
    dial: "+356",
    code: "MLT",
    country: "Malta",
  },
  {
    dial: "+692",
    code: "MHL",
    country: "Marshall Islands",
  },
  {
    dial: "+596",
    code: "MTQ",
    country: "Martinique",
  },
  {
    dial: "+222",
    code: "MRT",
    country: "Mauritania",
  },
  {
    dial: "+230",
    code: "MUS",
    country: "Mauritius",
  },
  {
    dial: "+52",
    code: "MEX",
    country: "Mexico",
  },
  {
    dial: "+691",
    code: "FSM",
    country: "Micronesia",
  },
  {
    dial: "+377",
    code: "MCO",
    country: "Monaco",
  },
  {
    dial: "+976",
    code: "MNG",
    country: "Mongolia",
  },
  {
    dial: "+382",
    code: "MNE",
    country: "Montenegro",
  },
  {
    dial: "+1-664",
    code: "MSR",
    country: "Montserrat",
  },
  {
    dial: "+212",
    code: "MAR",
    country: "Morocco",
  },
  {
    dial: "+258",
    code: "MOZ",
    country: "Mozambique",
  },
  {
    dial: "+95",
    code: "MMR",
    country: "Myanmar",
  },
  {
    dial: "+264",
    code: "NAM",
    country: "Namibia",
  },
  {
    dial: "+674",
    code: "NRU",
    country: "Nauru",
  },
  {
    dial: "+977",
    code: "NPL",
    country: "Nepal",
  },
  {
    dial: "+31",
    code: "NLD",
    country: "Netherlands",
  },
  {
    dial: "+687",
    code: "NCL",
    country: "New Caledonia",
  },
  {
    dial: "+64",
    code: "NZL",
    country: "New Zealand",
  },
  {
    dial: "+505",
    code: "NIC",
    country: "Nicaragua",
  },
  {
    dial: "+227",
    code: "NER",
    country: "Niger",
  },
  {
    dial: "+234",
    code: "NGA",
    country: "Nigeria",
  },
  {
    dial: "+683",
    code: "NIU",
    country: "Niue",
  },
  {
    dial: "+1-670",
    code: "MNP",
    country: "Northern Mariana Islands",
  },
  {
    dial: "+47",
    code: "NOR",
    country: "Norway",
  },
  {
    dial: "+968",
    code: "OMN",
    country: "Oman",
  },
  {
    dial: "+92",
    code: "PAK",
    country: "Pakistan",
  },
  {
    dial: "+680",
    code: "PLW",
    country: "Palau",
  },
  {
    dial: "+507",
    code: "PAN",
    country: "Panama",
  },
  {
    dial: "+675",
    code: "PNG",
    country: "Papua New Guinea",
  },
  {
    dial: "+595",
    code: "PRY",
    country: "Paraguay",
  },
  {
    dial: "+51",
    code: "PER",
    country: "Peru",
  },
  {
    dial: "+63",
    code: "PHL",
    country: "Philippines",
  },
  {
    dial: "+870",
    code: "PCN",
    country: "Pitcairn Islands",
  },
  {
    dial: "+48",
    code: "POL",
    country: "Poland",
  },
  {
    dial: "+351",
    code: "PRT",
    country: "Portugal",
  },
  {
    dial: "+974",
    code: "QAT",
    country: "Qatar",
  },
  {
    dial: "+82",
    code: "KOR",
    country: "South Korea",
  },
  {
    dial: "+373",
    code: "MDA",
    country: "Moldova",
  },
  {
    dial: "+40",
    code: "ROU",
    country: "Romania",
  },
  {
    dial: "+7",
    code: "RUS",
    country: "Russia",
  },
  {
    dial: "+250",
    code: "RWA",
    country: "Rwanda",
  },
  {
    dial: "+262",
    code: "REU",
    country: "Réunion",
  },
  {
    dial: "+290",
    code: "SHN",
    country: "St. Helena",
  },
  {
    dial: "+1-869",
    code: "KNA",
    country: "St. Kitts & Nevis",
  },
  {
    dial: "+1-758",
    code: "LCA",
    country: "St. Lucia",
  },
  {
    dial: "+590",
    code: "MAF",
    country: "St. Martin",
  },
  {
    dial: "+508",
    code: "SPM",
    country: "St. Pierre & Miquelon",
  },
  {
    dial: "+1-784",
    code: "VCT",
    country: "St. Vincent & Grenadines",
  },
  {
    dial: "+685",
    code: "WSM",
    country: "Samoa",
  },
  {
    dial: "+378",
    code: "SMR",
    country: "San Marino",
  },
  {
    dial: "+239",
    code: "STP",
    country: "São Tomé & Príncipe",
  },
  {
    dial: "+966",
    code: "SAU",
    country: "Saudi Arabia",
  },
  {
    dial: "+221",
    code: "SEN",
    country: "Senegal",
  },
  {
    dial: "+381",
    code: "SRB",
    country: "Serbia",
  },
  {
    dial: "+248",
    code: "SYC",
    country: "Seychelles",
  },
  {
    dial: "+232",
    code: "SLE",
    country: "Sierra Leone",
  },
  {
    dial: "+65",
    code: "SGP",
    country: "Singapore",
  },
  {
    dial: "+1-721",
    code: "SXM",
    country: "Sint Maarten",
  },
  {
    dial: "+421",
    code: "SVK",
    country: "Slovakia",
  },
  {
    dial: "+386",
    code: "SVN",
    country: "Slovenia",
  },
  {
    dial: "+677",
    code: "SLB",
    country: "Solomon Islands",
  },
  {
    dial: "+252",
    code: "SOM",
    country: "Somalia",
  },
  {
    dial: "+27",
    code: "ZAF",
    country: "South Africa",
  },
  {
    dial: "+500",
    code: "SGS",
    country: "South Georgia & South Sandwich Islands",
  },
  {
    dial: "+211",
    code: "SSD",
    country: "South Sudan",
  },
  {
    dial: "+34",
    code: "ESP",
    country: "Spain",
  },
  {
    dial: "+94",
    code: "LKA",
    country: "Sri Lanka",
  },
  {
    dial: "+970",
    code: "PSE",
    country: "Palestine",
  },
  {
    dial: "+249",
    code: "SDN",
    country: "Sudan",
  },
  {
    dial: "+597",
    code: "SUR",
    country: "Suriname",
  },
  {
    dial: "+46",
    code: "SWE",
    country: "Sweden",
  },
  {
    dial: "+41",
    code: "CHE",
    country: "Switzerland",
  },
  {
    dial: "+963",
    code: "SYR",
    country: "Syria",
  },
  {
    dial: "+886",
    code: "TWN",
    country: "Taiwan",
  },
  {
    dial: "+992",
    code: "TJK",
    country: "Tajikistan",
  },
  {
    dial: "+66",
    code: "THA",
    country: "Thailand",
  },
  {
    dial: "+389",
    code: "MKD",
    country: "North Macedonia",
  },
  {
    dial: "+670",
    code: "TLS",
    country: "Timor-Leste",
  },
  {
    dial: "+228",
    code: "TGO",
    country: "Togo",
  },
  {
    dial: "+690",
    code: "TKL",
    country: "Tokelau",
  },
  {
    dial: "+676",
    code: "TON",
    country: "Tonga",
  },
  {
    dial: "+1-868",
    code: "TTO",
    country: "Trinidad & Tobago",
  },
  {
    dial: "+216",
    code: "TUN",
    country: "Tunisia",
  },
  {
    dial: "+90",
    code: "TUR",
    country: "Turkey",
  },
  {
    dial: "+993",
    code: "TKM",
    country: "Turkmenistan",
  },
  {
    dial: "+1-649",
    code: "TCA",
    country: "Turks & Caicos Islands",
  },
  {
    dial: "+688",
    code: "TUV",
    country: "Tuvalu",
  },
  {
    dial: "+256",
    code: "UGA",
    country: "Uganda",
  },
  {
    dial: "+380",
    code: "UKR",
    country: "Ukraine",
  },
  {
    dial: "+971",
    code: "ARE",
    country: "United Arab Emirates",
  },
  {
    dial: "+44",
    code: "GBR",
    country: "UK",
  },
  {
    dial: "+255",
    code: "TZA",
    country: "Tanzania",
  },
  {
    dial: "+1-340",
    code: "VIR",
    country: "U.S. Virgin Islands",
  },
  {
    dial: "+598",
    code: "URY",
    country: "Uruguay",
  },
  {
    dial: "+998",
    code: "UZB",
    country: "Uzbekistan",
  },
  {
    dial: "+678",
    code: "VUT",
    country: "Vanuatu",
  },
  {
    dial: "+58",
    code: "VEN",
    country: "Venezuela",
  },
  {
    dial: "+84",
    code: "VNM",
    country: "Vietnam",
  },
  {
    dial: "+681",
    code: "WLF",
    country: "Wallis & Futuna",
  },
  {
    dial: "+967",
    code: "YEM",
    country: "Yemen",
  },
  {
    dial: "+260",
    code: "ZMB",
    country: "Zambia",
  },
  {
    dial: "+263",
    code: "ZWE",
    country: "Zimbabwe",
  },
];

const getPhoneCode = () => {
  return phoneCode;
};

export { phoneCode, getPhoneCode };
