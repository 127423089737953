
import React , { useEffect, useState, useRef } from "react";
import { getPrivacy } from "../services/PublicForm";
const App = () => {
  const initialized = useRef(false);
  const [message, setMessage] = useState("");
  useEffect( /* istanbul ignore next */ () => {
     /* istanbul ignore else */
    if(!initialized.current){
      getPrivacy("channelpartner")
        .then((res) => {
          let resData = res.data;
           /* istanbul ignore else */
          if (resData.resultCode === "20000") {
            setMessage(resData.resData[0]?.html || "")
          }
        }).catch((error) => {

        });
      initialized.current = true;
    };
  }, []);

return <>
  <div className="public-form">
    <div className="ui-form">
      <h2> Privacy Policy </h2>
      {
        message ? <>
        { <div dangerouslySetInnerHTML={{ __html: (message) }} /> }
      </>:<>
        <div style={{display:'none'}}>  No Data  </div>
      </>
    }
    </div>
  </div>
  </>;
};

export default App;
